@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@no-margin-top: ~'@{ra-prefix}-cms-no-margin-top';
@no-margin-bottom: ~'@{ra-prefix}-cms-no-margin-bottom';

:export {
  cmsNoMarginTop: @no-margin-top;
  cmsNoMarginBottom: @no-margin-bottom;
}

.@{no-margin-top} {
  margin-top: 0;
}

.@{no-margin-bottom} {
  margin-bottom: 0;
}
