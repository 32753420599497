// Import styles
@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

// Define module less variables
@home-trending-cls: ~'@{ra-prefix}-home-trending';
@home-trending-arrow-cls: ~'@{home-trending-cls}-arrow';

// Export module less variables
:export {
  homeTrendingCls: @home-trending-cls;
  homeTrendingArrowCls: @home-trending-arrow-cls;
}

@arrow-width: 24px;
@arrow-height: 20px;

.@{home-trending-cls} {
  .ra-section-content-body {
    position: relative;
  }

  .@{home-trending-arrow-cls} {
    position: absolute;
    left: calc(~'50% - @{arrow-width}');
    z-index: @ra-zindex-home-trending-arrow;
    width: 0;
    height: 0;
    border-left: @arrow-width solid transparent;
    border-right: @arrow-width solid transparent;
    border-top: @arrow-height solid @ra-color-white;
  }
}
